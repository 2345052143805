import React, { useState, useRef } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import BreadCrumbs from "../../Component/BreadCrumbs/BreadCrumbs";

const Career = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    select: "",
    referred: false,
    referred1: false,
    resume: null,
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (type === "file") {
      setFormData({
        ...formData,
        resume: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Step 1: Upload the file to CPanel
    const data = new FormData();
    data.append("file", formData.resume);

    try {
      const uploadResponse = await axios.post(
        "https://new.shieldit.co.in/upload.php",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.data.status === "success") {
        const file_url = uploadResponse.data.file_url;

        // Step 2: Send email using EmailJS
        const templateParams = {
          user_name: formData.name,
          user_email: formData.email,
          user_phone: formData.phone,
          select_option: formData.select,
          referred: formData.referred ? "Yes" : "No",
          message: formData.message,
          file_url: file_url,
        };

        const emailResponse = await emailjs.send(
          "service_59mygim",
          "template_yeoxc6l",
          templateParams,
          "1APIgSiYMwbuckOPE"
        );
        console.log(
          "Email sent successfully!",
          emailResponse.status,
          emailResponse.text
        );
        alert("File uploaded and email sent successfully");
      } else {
        console.error("Error uploading the file:", uploadResponse.data);
        alert("Error uploading the file");
      }
    } catch (error) {
      console.error("Error uploading the file:", error);
      alert("Error uploading the file");
    }
  };
  return (
    <div>
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />
          <h1>Career</h1>
        </div>
      </div>

      <section className="contact-page-content sec-pad">
        <div className="container careerForm">
          <div className="sec-title text-center">
            <h2>Apply Now</h2>
          </div>

          <form
            ref={form}
            action="#"
            className="meeting-form contact-form careerForm"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-6">
                <label>Name *</label>
                <input
                  placeholder="Your name"
                  type="text"
                  id="name"
                  name="name"
                  required
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6">
                <label>Email *</label>
                <input
                  placeholder="Email address"
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6">
                <label>Number *</label>
                <input
                  placeholder="Phone Number"
                  type="number"
                  id="phone"
                  name="phone"
                  required
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6">
                <label>How did you find about this position *</label>
                <select
                  name="select"
                  id="select"
                  className="selectpickers dropdown-menuu"
                  required
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="classNameifieds">classNameifieds</option>
                  <option value="Internet">Internet</option>
                  <option value="Friend">Friend</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div className="col-md-6">
                <label>Were you referred by an existing employee *</label>
                <div className="careerform">
                  <input
                    type="checkbox"
                    id="referred"
                    name="referred"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="referred"> Yes</label>
                  <input
                    type="checkbox"
                    id="referred1"
                    name="referred1"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="referred1"> No</label>
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="file">Upload Your Resume *</label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="col-md-12">
                <textarea
                  name="message"
                  id="message"
                  onChange={handleInputChange}
                  placeholder="Comment or questions"
                ></textarea>
              </div>

              <div className="col-md-12">
                <div className="btn-box">
                  <button type="submit" className="thm-btn">
                    Submit
                  </button>
                  <span className="btn-tag-line">
                    Start the thrill <i className="payonline-icon-share"></i>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Career;
