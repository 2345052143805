import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

const PopUpForm = ({ show, handleClose }) => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email via EmailJS
    emailjs
      .sendForm(
        "service_59mygim",
        "template_4pmu0pm",
        form.current,
        "1APIgSiYMwbuckOPE"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send email.");
        }
      );

    handleClose();
  };

  return (
    <>
      <div className={`popup ${show ? "show" : ""}`}>
        <div className="popup-content">
          <span className="close" onClick={handleClose}>
            <i class="fa-solid fa-xmark"></i>
          </span>

          <span className="iconPopup">
            <i className="payonline-icon-handshake"></i>
          </span>
          <h2 className="popuptitle">Let's Connect and Collaborate!</h2>

          <form ref={form} className="formPopup" onSubmit={handleSubmit}>
            <label>
              <input
                placeholder="Enter Full Name"
                type="text"
                id="name"
                name="name"
                required
                onChange={handleInputChange}
              />
            </label>
            <label>
              <input
                placeholder="Enter Your Number"
                type="number"
                id="phone"
                name="phone"
                required
                onChange={handleInputChange}
              />
            </label>
            <label>
              <input
                placeholder="Enter Your Email Id"
                type="email"
                id="email"
                name="email"
                required
                onChange={handleInputChange}
              />
            </label>
            <button className="popbtn" type="submit">
              Request Meeting
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PopUpForm;
