import React, { useState } from "react";
import BreadCrumbs from "../../../Component/BreadCrumbs/BreadCrumbs";

const PayrollSolution = () => {
  const [activeTab, setActiveTab] = useState("effective");
  const [activedAccountingSolution, setActivedAccountingSolution] =
    useState("management");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleAccountingSolution = (tabName) => {
    setActivedAccountingSolution(tabName);
  };
  return (
    <div>
      <div class="inner-banner text-center">
        <div class="container">
          <BreadCrumbs />

          <h1>Payroll Solution</h1>
        </div>
      </div>

      <section class="video-box-style-four sec-pad solutions-details-page">
        <div class="container-fluid">
          <div class="row no-gutters">
            <div class="col-lg-6">
              <div class="video-box-content">
                <div class="sec-title">
                  <span>INTRODUCTION </span>
                  <h2>
                    Risk-Free Payroll: Integrated Solutions for Enhanced
                    Efficiency
                  </h2>
                </div>
                <p>
                  Our payroll outsourcing services ensure a smooth and efficient
                  payroll process, freeing you to focus on your primary business
                  goals rather than managing payslips. We simplify this complex
                  process, addressing challenges such as timely payments, cost
                  savings, and tax and regulatory compliance. Handling payroll
                  in-house can decrease productivity and divert focus from core
                  activities.
                </p>
                <p>
                  Our Payroll Outsourcing solutions are designed to enhance
                  precision and efficiency, automating processes and delivering
                  error-free reports. As a comprehensive Payroll Outsourcing
                  Service Provider in India, we offer end-to-end solutions that
                  save you time and money while ensuring compliance.
                </p>
              </div>
            </div>

            <div class="col-lg-6 d-flex">
              <div class="video-box my-auto">
                <img
                  src={require("../../../Asserts/images/video-3-1.jpg")}
                  alt="Awesome Image"
                />
                <div class="box">
                  <a href="#" class="video-popups">
                    {/* <span className="playbtn">
                      <i class="fa-solid fa-play "></i>
                    </span> */}
                    {/* <span class="btn-tag-line">
                      Learn about payonline <i class="payonline-icon-share"></i>
                    </span> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="time-tab-style-one sec-pad gray-bg">
        <div class="container">
          <div class="sec-title text-center">
            <span>What We Offer</span>
            <h2>
              Payroll Solution that <br />
              gets results
            </h2>
            <p className="payroll-whatwedo">
              Shield IT guarantees seamless and efficient management with our
              comprehensive payroll outsourcing services. Our expertise ensures
              accurate and timely payroll processing, prioritizing the security
              of your sensitive information through advanced cloud-based
              software and robust data controls.
            </p>
          </div>

          <ul class="nav nav-tabs tab-title clearfix" role="tablist">
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "effective" ? "active" : ""
                }`}
                href="#online"
                role="tab"
                data-toggle="tab"
                onClick={() => handleTabClick("effective")}
              >
                <h3>Define tax efficient salary structure</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "accurate" ? "active" : ""
                }`}
                href="#time"
                role="tab"
                data-toggle="tab"
                onClick={() => handleTabClick("accurate")}
              >
                <h3>On time processing of payroll</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "accounting" ? "active" : ""
                }`}
                href="#invision"
                role="tab"
                data-toggle="tab"
                onClick={() => handleTabClick("accounting")}
              >
                <h3>Portal for pay slips & tax declarations</h3>
              </a>
            </li>
          </ul>

          {/* effective */}
          {activeTab === "effective" && (
            <div class="single-tab-content">
              <div class="row">
                <div class="col-xl-5 col-lg-12">
                  <div class="img-box">
                    <img
                      src={require("../../../Asserts/images/tab-1-1.png")}
                      alt="Awesome Image"
                    />
                  </div>
                </div>

                <div class="col-xl-7 col-lg-12">
                  <div class="text-box">
                    <p>
                      At Shield IT, our accounting solutions are designed to be
                      highly effective, providing you with accurate financial
                      management and insightful reporting. We ensure compliance
                      with all regulations, streamline your financial processes,
                      and deliver results that help your business grow. Our team
                      of experienced professionals is dedicated to maintaining
                      the highest standards of accuracy and efficiency, allowing
                      you to focus on your core business activities. Whether
                      it's managing daily transactions, preparing financial
                      statements, or ensuring timely tax filings, we handle it
                      all with precision. Trust Shield IT for dependable,
                      efficient accounting services that support your business's
                      success and growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Accurate */}
          {activeTab === "accurate" && (
            <div class="single-tab-content">
              <div class="row">
                <div class="col-xl-5 col-lg-12">
                  <div class="img-box">
                    <img
                      src={require("../../../Asserts/images/tab-1-2.jpg")}
                      alt="Awesome Image"
                    />
                  </div>
                </div>

                <div class="col-xl-7 col-lg-12">
                  <div class="text-box">
                    <p>
                      At Shield IT, accuracy is at the core of our accounting
                      solutions. We pride ourselves on delivering precise
                      financial management, ensuring every detail is
                      meticulously handled. Our expert team utilizes advanced
                      tools and technologies to maintain the highest standards
                      of accuracy in all financial transactions, reports, and
                      tax filings. We understand the critical importance of
                      reliable financial data for making informed business
                      decisions. With Shield IT, you can trust that your
                      financial information is accurate, up-to-date, and
                      compliant with all regulations. Our commitment to accuracy
                      helps your business avoid costly errors and enhances your
                      financial stability and growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Accounting  */}
          {activeTab === "accounting" && (
            <div class="single-tab-content">
              <div class="row">
                <div class="col-xl-5 col-lg-12">
                  <div class="img-box">
                    <img
                      src={require("../../../Asserts/images/tab-1-3.jpg")}
                      alt="Awesome Image"
                    />
                  </div>
                </div>

                <div class="col-xl-7 col-lg-12">
                  <div class="text-box">
                    <p>
                      At Shield IT, our results-driven accounting services are
                      focused on delivering tangible benefits to your business.
                      We go beyond basic number-crunching, offering strategic
                      financial insights that help you make informed decisions
                      and drive growth. Our experienced team works diligently to
                      ensure your financial records are accurate and compliant,
                      providing you with the confidence to focus on your core
                      business activities. By leveraging advanced accounting
                      tools and methodologies, we streamline your financial
                      processes, reduce inefficiencies, and uncover
                      opportunities for cost savings. Trust Shield IT to provide
                      results-driven accounting solutions that enhance your
                      business's financial health and support its long-term
                      success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section class="sec-pad solutions-tab-one">
        <div class="container">
          <div class="sec-title text-center">
            <span>What We Do</span>
            <h2>
              Complete Payroll Solution <br />
              management in one application
            </h2>
          </div>

          <ul class="nav nav-tabs tab-title clearfix" role="tablist">
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "management" ? "active" : ""
                }`}
                href="#times"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("management")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-timer-1"></i>
                </span>
                <h3>Leave and Attendance</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "transactions" ? "active" : ""
                }`}
                href="#exchange"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("transactions")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-event"></i>
                </span>
                <h3> Payroll Management</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "insights" ? "active" : ""
                }`}
                href="#time-off"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("insights")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-disable-alarm"></i>
                </span>
                <h3>
                  Labor <br />
                  Laws
                </h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "assurance" ? "active" : ""
                }`}
                href="#web-time"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("assurance")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-time-management"></i>
                </span>
                <h3>Employee Self-Service</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "handling" ? "active" : ""
                }`}
                href="#allocation"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("handling")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-employee"></i>
                </span>
                <h3>Payroll Reports</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "support" ? "active" : ""
                }`}
                href="#report"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("support")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-report"></i>
                </span>
                <h3>Income Tax Preparation</h3>
              </a>
            </li>
          </ul>

          {activedAccountingSolution === "management" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Leave and Attendance</span>
                    <h2>Management of Leave and Attendance</h2>
                  </div>

                  <p>
                    Our leave and attendance management site makes sure that you
                    and your team always have access to the most accurate and
                    up-to-date information through online workflows. With
                    completely integrated systems, you may create attendance
                    reports with only one click for payroll processing.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "transactions" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Payroll Management Services</span>
                    <h2>Complete Payroll Management Services</h2>
                  </div>

                  <p>
                    Thanks to our cutting-edge and scalable technology, Offshore
                    Accounting & Taxation Services (SHIELD IT) has been able to
                    manage end-to-end payroll operations with remarkable
                    efficacy and efficiency. Payroll outsourcing services
                    include timely payroll processing, verification of
                    investment proofs, tax filings, reporting, and analytics.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "insights" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Labor Laws</span>
                    <h2>Compliance with Labor Laws</h2>
                  </div>

                  <p>
                    We offer statutory filing and payroll-related compliance
                    services for income tax, labor welfare fund, ESIC, provident
                    fund, and professional tax.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "assurance" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Employee Self-Service</span>
                    <h2>Portal for Employee Self-Service</h2>
                  </div>

                  <p>
                    Workers can review their paystubs, file tax returns, see
                    "what-if" scenarios at various tax investment levels, assess
                    whether the old or new tax regime is better for them and
                    make that decision, view tax computations, and bring up any
                    concerns they may have regarding their pay. Our payroll
                    helpdesk promptly addresses and resolves concerns brought
                    out, all within predetermined time frames.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "handling" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Payroll Reports</span>
                    <h2>Reports</h2>
                  </div>

                  <p>
                    We give your HR manager a comprehensive variance report and
                    salary register so they may examine and approve the payroll.
                    Pay statements, reports on flexible benefits, pay breakdowns
                    by cost or profit center, salary journal entries for upload
                    into the accounting system, and salary bank files for upload
                    into the bank's portal are just a few of the additional
                    reports that are accessible.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "support" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Income Tax Preparation</span>
                    <h2>Better Income Tax Preparation</h2>
                  </div>

                  <p>
                    Employees may prepare their tax declarations and be aware of
                    all possible tax savings opportunities thanks to the
                    integrated tax planner, which compiles all deductions into
                    well-organized lists. Giving employees full control over
                    their taxes is possible with a report that details how the
                    tax is computed after accounting for different deductions.
                    Workers have the option to choose between the existing and
                    new tax regimes by simulating tax calculations under each.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Steps Section Start */}
      <section class="easy-steps-style-one sec-pad">
        <div class="container">
          <div class="sec-title text-center light">
            <h2>Key Differentiators</h2>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="single-easy-step-one">
                <i class="payonline-icon-bars"></i>
                <h3>
                  <a href="#">Reliable Payroll Accuracy</a>
                </h3>
                <p>
                  Our payroll specialists are completely trustworthy and
                  dependable to calculate payroll accurately. You can rely on
                  timely and accurate payroll processing every single time.
                </p>

                <span class="step-count">01 </span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="single-easy-step-one">
                <i class="payonline-icon-handshake"></i>
                <h3>
                  <a href="#">An Honorable Manager</a>
                </h3>
                <p>
                  Our payroll specialists are completely trustworthy and
                  dependable to calculate payroll accurately. You can rely on
                  timely and accurate payroll processing every single time.
                </p>

                <span class="step-count">02</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="single-easy-step-one">
                <i class="payonline-icon-padlock"></i>
                <h3>
                  <a href="#">Entire confidentiality</a>
                </h3>
                <p>
                  Shield IT guarantees absolute confidentiality in payroll
                  management, ensuring secure, accurate, and timely processing
                  with advanced cloud-based software and strict data controls.
                </p>

                <span class="step-count">03 </span>
              </div>
            </div>
          </div>
          <div class="row newrow-section">
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="single-easy-step-one">
                <i class="payonline-icon-checkmark-outlined-circular-button"></i>
                <h3>
                  <a href="#">customized reports for you</a>
                </h3>
                <p>
                  Shield IT delivers customized reports tailored to your needs,
                  providing precise, clear, and actionable insights to enhance
                  your payroll management and overall business efficiency.
                </p>

                <span class="step-count">04 </span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="single-easy-step-one">
                <i class="payonline-icon-chat"></i>
                <h3>
                  <a href="#">Investing in reliable technologies</a>
                </h3>
                <p>
                  Shield IT invests in reliable technologies, utilizing
                  cutting-edge tools to ensure efficient, secure, and accurate
                  payroll processing, enhancing your business's overall
                  performance and compliance.
                </p>

                <span class="step-count">05 </span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div class="single-easy-step-one">
                <i class="payonline-icon-settings"></i>
                <h3>
                  <a href="#">Putting best practices into practice</a>
                </h3>
                <p>
                  Shield IT excels in implementing best practices, ensuring
                  top-notch payroll management with precision, security, and
                  compliance, allowing you to focus on core business goals.
                </p>

                <span class="step-count">06 </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Steps Section end */}

      {/* Contact Us Section Start  */}
      <section class="cta-one sec-pad ">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-7">
              <div class="cta-one-content">
                <div class="sec-title">
                  <span>Get in Touch</span>
                  <h2>
                    Talk With Our Experts <br />
                    Today
                  </h2>
                </div>
                <form action="#" class="cta-form">
                  <select class="selectpicker">
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                  </select>
                  <div class="btn-box">
                    <button type="submit" class="thm-btn">
                      Contact Us
                    </button>
                    <div class="btn-tag-line">
                      <i class="payonline-icon-share"></i>Are you ready get?
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 col-md-5 text-right">
              <img
                src={require("../../../Asserts/images/mockup-1-2.png")}
                alt="Awesome Image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us Section end  */}
    </div>
  );
};

export default PayrollSolution;
